import { Edit } from "lucide-react";
import type React from "react";
import { useNavigate } from "react-router-dom";
import type {
  ChallengeData,
  Exam,
  GradedChallenge,
  ProgressIndicatorRowProps,
} from "../types";
import ProgressToken from "./ProgressToken";

const getConstructionReason = (exam: Exam, challengeType: string): string => {
  if (!exam || !challengeType) return "Challenge configuration is incomplete";

  if (exam.examType === "AIME") {
    if (challengeType === "firstTen") {
      return "AIME exams don't have First Ten challenges as they only contain 15 problems";
    }
    if (challengeType === "16-20" || challengeType === "21-25") {
      return "AIME exams only contain problems 1-15";
    }
  }

  if (exam.examType === "AMC-8") {
    if (challengeType === "16-20" || challengeType === "21-25") {
      return "AMC-8 exams only contain problems 1-25";
    }
    if (challengeType.includes("(B)")) {
      return "AMC-8 exams don't have B versions";
    }
  }

  if (exam.examType === "Olympiad") {
    if (challengeType === "firstTen" || challengeType.includes("11-15")) {
      return "Olympiad problems are longer format and don't follow the standard 1-25 structure";
    }
  }

  if (exam.examType === "ARML") {
    return "ARML challenges are under development - coming soon!";
  }

  if (Number.parseInt(exam.year) < 2000) {
    return "Challenges for pre-2000 exams are still being processed and validated";
  }

  return "This challenge type is still under development for this exam format";
};

const getChallengeTypeDisplay = (challengeType: string): string => {
  switch (challengeType) {
    case "firstTen":
      return "First Ten";
    case "firstTen (B)":
      return "First Ten (B)";
    case "11-15":
      return "Problems 11-15";
    case "16-20":
      return "Problems 16-20";
    case "21-25":
      return "Problems 21-25";
    default:
      return "Unknown";
  }
};

const ProgressIndicatorRow: React.FC<ProgressIndicatorRowProps> = ({
  exam,
  progress,
  isAdminMode,
  challenges,
  gradedChallenges,
  viewAsUserProfile,
  userProfile,
  isViewingAsOtherUser,
}) => {
  const navigate = useNavigate();

  const handleChallengeClick = (challengeId?: string) => {
    if (isViewingAsOtherUser || !challengeId) return;
    navigate(`/challenge/${challengeId}`);
  };

  const progressRanges = ["1-10", "1-10 (B)", "11-15", "16-20", "21-25"];
  const challengeTypes = [
    "firstTen",
    "firstTen (B)",
    "11-15",
    "16-20",
    "21-25",
  ];

  const getTokenTooltip = (
    challengeType: string,
    challenge?: ChallengeData,
    gradedChallenge?: GradedChallenge,
  ): string => {
    const viewedUserName = viewAsUserProfile?.name?.split(" ")[0] || "User";
    const prefix = isViewingAsOtherUser
      ? `${viewedUserName} has `
      : "You have ";

    if (!challenge) {
      return `Under construction - ${getConstructionReason(exam, challengeType)}`;
    }

    if (!gradedChallenge) {
      return `${prefix}not attempted the ${getChallengeTypeDisplay(
        challengeType,
      )} challenge yet`;
    }

    switch (gradedChallenge.status) {
      case "worked_on":
        return `${prefix}taken the ${getChallengeTypeDisplay(
          challengeType,
        )} - needs answer checking!`;
      case "answer_checked":
        return `${prefix}completed and reviewed answers on the ${getChallengeTypeDisplay(
          challengeType,
        )}`;
      case "reviewed":
        return `${prefix}demonstrated mastery of the ${getChallengeTypeDisplay(
          challengeType,
        )}!`;
      default:
        return "Challenge status unknown";
    }
  };

  return (
    <div className="flex items-center p-2 border-b border-gray-200 hover:bg-gray-50">
      <div className="flex space-x-2">
        {progressRanges.map((range, index) => {
          const challenge = challenges.find(
            (c) =>
              c.type === challengeTypes[index] && c.examIds?.includes(exam.id),
          );

          const gradedChallenge = challenge
            ? gradedChallenges.find((gc) => gc.challengeId === challenge.id)
            : undefined;

          return (
            <ProgressToken
              key={range}
              challengeId={challenge?.id || null}
              status={progress ? progress[range] : -1}
              gradedChallenge={gradedChallenge}
              onClick={() => handleChallengeClick(challenge?.id)}
              isDisabled={isViewingAsOtherUser}
              tooltip={getTokenTooltip(
                challengeTypes[index],
                challenge,
                gradedChallenge,
              )}
            />
          );
        })}
      </div>
      <div className="flex-grow ml-4">
        <div className="text-sm font-medium text-gray-800">{exam.name}</div>
        <div className="text-xs text-gray-500">{exam.year}</div>
      </div>
      {isAdminMode && (
        <button
          type="button"
          onClick={() => navigate(`/exam/${exam.id}`)}
          className="ml-4 bg-blue-500 text-white px-2 py-1 rounded text-xs hover:bg-blue-600 transition-colors flex items-center"
          aria-label={`Edit ${exam.name}`}
        >
          <Edit size={12} className="mr-1" />
          Edit
        </button>
      )}
    </div>
  );
};

export default ProgressIndicatorRow;
