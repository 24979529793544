import { ChevronDown, ChevronUp } from "lucide-react";
import type React from "react";
import { useState } from "react";
import type { Exam, ExamTypeSectionProps } from "../types";
import ProgressIndicatorRow from "./ProgressIndicatorRow";

const ExamTypeSection: React.FC<ExamTypeSectionProps> = ({
  type,
  exams,
  progress,
  isAdminMode,
  challenges,
  gradedChallenges,
  viewAsUserProfile,
  userProfile,
  isViewingAsOtherUser,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Get canonical 21-25 challenges
  const canonical2125Challenges = challenges.filter(
    (c) => c.type === "21-25" && !!c.canonical && c.examIds?.length > 0,
  );

  // Filter exams to just those that appear first in canonical 21-25 challenges
  const filteredExams = exams.filter((exam) =>
    canonical2125Challenges.some((c) => c.examIds?.[0] === exam.id),
  );

  const getDisplayName = (exam: Exam) => {
    const challenge = canonical2125Challenges.find(
      (c) => c.examIds?.[0] === exam.id,
    );
    if (!challenge?.name) return `${exam.year} ${exam.name}`;
    return challenge.name.replace(/^21-25 Challenge for\s*/, "").trim();
  };

  return (
    <div className="mb-3">
      <button
        type="button"
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center justify-between w-full p-2 bg-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        aria-expanded={isExpanded}
        aria-controls={`section-${type}`}
      >
        <span className="font-semibold text-sm">{type}</span>
        {isExpanded ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </button>

      {isExpanded && (
        <div id={`section-${type}`} className="mt-2">
          {filteredExams
            .sort((a, b) => Number(a.year) - Number(b.year))
            .map((exam) => (
              <ProgressIndicatorRow
                key={exam.id}
                exam={{ ...exam, name: getDisplayName(exam) }}
                progress={progress[exam.id]}
                isAdminMode={isAdminMode}
                challenges={challenges}
                gradedChallenges={gradedChallenges}
                viewAsUserProfile={viewAsUserProfile}
                userProfile={userProfile}
                isViewingAsOtherUser={isViewingAsOtherUser}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default ExamTypeSection;
