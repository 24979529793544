import { ChevronRight } from "lucide-react";
import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

interface BlogPost {
  id: string;
  date: string;
  title: string;
  content: string;
  pinned?: boolean;
}

const blogPosts: Record<string, BlogPost[]> = {
  Updates: [
    {
      id: "shipping-stability-nov-2024",
      date: "2024-11-14",
      title: "Shipping Stability",
      content: `Instead of flashy features, or even big bug fixes, sometimes the big win is just to get features to be a little bit more reliable.

With more and more Shadow AIME challenges ([1](https://www.grindolympiads.com/challenge/PXNzNd1WAquj1OaRRsw6), [2](https://www.grindolympiads.com/challenge/4oVPZUdCJrxVUloMnU9f), [3](https://www.grindolympiads.com/challenge/wPPDnsw2YymxzDfmGop1)) being put up this week in the aftermath of the AMC-10B, I've become painfully aware of reliability issues such as (1) actions not recording, (2) problems not advancing after answers are submitted, (3) [timer issues that are already documented in the roadmap section](/blog/timer-issues), but which manifest in console error messages. Some of these issues traced back to the way we were storing Challenge Runs in the database. These are documents in Firestore that belong to the "challengeRuns" collection. While it's not strictly necessary for these documents to map "id" to a string (since documents themselves know their id), it is the case that many of these documents were being stored with id set to an empty string "". By simply tweaking the backend to ensure that the field is always stored with the correct value, we were able to get a drastically improved experience across the tests. Along the way we did some of the work to get the timers improved -- they are still a work in progress, but incrementally better for what it's worth.

Would it be better to get rid of the id field and never refer to it in the code? Yeah it would probably be better. But it's not the most pressing issue that will help kids prepare for the AMC-8, the upcoming AIMEs, Mathcounts, and potentially the USAJMO. So we'll keep trying to facilitate preparation for those events as best we can, and maybe fix the ids thing on a rainy day.

-John`,
    },
    {
      id: "chasing-aime-nov-2024",
      date: "2024-11-13",
      title: "Chasing AIME",
      content: `We have downloaded most of the AIME historical content and started challenge support for AIMEs. I am not an advocate of the blocks-of-5 paradigm that I created for AMC 8/10/12 when it comes to the AIMEs. Rather you should expect to see "Target Practice" motivated by ARML and the Mathcounts Target round. Basically instead of 10 problems of roughly equal difficulty, I split adjacent AIME exams into 15 pairings of problem X and problem X+7.5 from the other exam (so 1a with 8b, 1b with 9a, 2a with 9b, 2b with 9a, you get it...)

For my kids who are just seeing AIME problems for the first time I think 2 problems per day is reasonable and leaves room for coaching (it would take about a year of daily practice to get through all the problems at this pace). For students who are impatient to grind through the AIME in the next couple months, we'll see if it has any practical value...

If you want to try out the format before it's fully released, there is a "shadow target practice" challenge available at https://www.grindolympiads.com/challenge/PXNzNd1WAquj1OaRRsw6

-John`,
    },
    {
      id: "amc-survey-nov-2024",
      date: "2024-11-11",
      title: "HEY YO... Survey Time",
      content: `With AMC-10A scores released the past couple days, I'm getting a sense that that math class you sit in 5 days a week in school might impact your score more than you think it does. To test this hypothesis I have put up a survey at grindolympiads.com/survey.

If you took the AMC-10A this year, fill it out! I want to know:
- What you scored
- How happy you were with your score
- What math class you're in
- What math class you think you should be in

Maybe I'm wrong, and preparation still matters more than what class they've put you in. Let's find out what the numbers say.

-John`,
    },
    {
      id: "latex-update-nov-2024",
      date: "2024-11-09",
      title: "Aw snap! We fixed it",
      content: `Well, we finally got rid of the crashes. I knew that it was probably caused by the mathjax library that was rendering LaTeX, but I didn't have a concrete plan for attacking it.

Finally this weekend I decided to switch from using the better-react-mathjax package to using react-latex-next. There were some attempts in the middle to use mathlive, but I gave up on that.

So far things are looking good. I'll experiment with trying out some other LaTeX notations (e.g. matrices etc) and see if we have any new capabilities. In the meantime this is a big win for the test taking experience.

Happy Grinding!

-John`,
    },
    {
      id: "first-post",
      date: "2024-11-01",
      title: "Welcome to the GrindOlympiads Dev Blog",
      content: `Welcome to the GrindOlympiads Developer Blog! This is where we'll share updates about:

- New features and improvements
- Technical changes and optimizations
- Platform stability updates
- Upcoming developments

We believe in transparency and want to keep our community informed about the continuous improvements we're making to enhance your mathematical journey.

Stay tuned for regular updates as we continue to evolve and improve the platform!

-John`,
    },
  ],
  Roadmap: [
    {
      id: "pinned-roadmap-note",
      date: "2024-11-11",
      title: "[PINNED] About the Roadmap Section",
      content: `This section of the blog is a discussion of issues on the site that have known deficiencies that I am working on fixing. It's not entirely clear how I will handle these blog entries once the issues themselves have been fixed.

I want transparency in this section so users will understand the direction that the site is headed and the progression of features as they gradually transition from broken to functional.

-John`,
      pinned: true,
    },
    {
      id: "show-answers-nov-2024",
      date: "2024-11-14",
      title: "What was the right answer?",
      content: `Atlas keeps prodding me to have the site show you the right answer after you do a challenge. He is obviously right - this is an important feature - and hopefully it will not be long before students can see the right answer and see their progress on the challenges. I will also be rearchitecting the challenges page soon to transpose the matrix of challenges so it is more obvious that the intended path is for students to do all the first ten challenges year by year, then do the 11-15 challenges, etc. Also students should see little badges / star ratings indicating which ones they have done. We actually had this feature a few weeks ago but it was inadvertently broken while I was modifying the interface to make sure that I as an admin was able to see these badges on behalf of other users. Oops!

-John`,
    },
    {
      id: "notifications-nov-2024",
      date: "2024-11-13",
      title: "Sorry for the notifications",
      content: `We know that the notifications feature is pointless. It has not been a priority.

-John`,
    },
    {
      id: "placeholders-nov-2024",
      date: "2024-11-12",
      title: "4,906 placeholders",
      content: `As you know if you have navigated through the AMC challenges on this site, many of the exams are unfinished. I dumped the Firestore database to json and ran this command: "cat firestore_dump.json | grep -c placeholder" and at latest count it told me that there are 4,906 placeholder strings in the database, which in a nutshell means that if you add up all the options (A-E for multiple choice problems) and problem statements that are not actually filled in, you get 4,906.

This isn't an immediate fix, but it is one of the many cases where LLMs (Claude and GPT in this case) are extremely pragmatic tools for the job. There is the option of paying x amount of dollars to just pass all of the documents up to your favorite LLM with API calls and having it dispatch function calls to overwrite the problems in the database. This is not my preferred approach (although it may be demonstrably the best approach). What I prefer is to have the consumer interface (what many of you think of as ChatGPT, or the Claude web interface, which is the one I will probably use) populated with other good examples of output files and then I feed tests in one at a time and ask it to generate new files. Then I have a script that I run against that file and it asks me one by one "do you want to accept these changes for 2011 problem 6" etc and I can see exactly what it is going to do. It is a bit more tedious and manual, and yet it is also very satisfying to watch the changes go in one by one and magically render beautifully on the site.

So there it is, heading into today's AMC-10B, the number is 4,906. Stay tuned, and Happy Grinding!

-John

Exercise for the reader: write an interesting problem that hinges on the following
* Factorize(2024)= 1×2×2×2×11×23
* Factorize(4906)= 1×2×11×223`,
    },
    {
      id: "timer-issues",
      date: "2024-11-10",
      title: "Broken timers",
      content: `I need to fix the timers on the tests. There is supposed to be one timer ticking at all times, either indicating how fast you can speed run the question you're on, or how long it takes you to check your answer, or how long you have paused in the middle of the test. Right now the timers are coded very poorly and even though this information is available to me after you take the test, you are not able to see it during your work.

-John`,
    },
    {
      id: "latex-crashes",
      date: "2024-10-27",
      title: "Known Issue: Tests Crashing",
      content: `[FIXED - See [update from Nov 9th](/blog/latex-update-nov-2024)]

Having a problem with the browser crashing after you've looked at a problem for a while during a challenge or test. I'm pretty sure this is caused by the MathJax library we're using to render LaTeX equations. Hard to debug the issue with the library because once the browser crashes, the console goes away.

-John`,
    },
  ],
};

const findPostByPath = (
  path: string,
): { post: BlogPost | null; section: string | null } => {
  const postId = path.split("/").pop();

  for (const [section, posts] of Object.entries(blogPosts)) {
    const post = posts.find((p) => p.id === postId);
    if (post) {
      return { post, section };
    }
  }

  return { post: null, section: null };
};

interface PostHoverCardProps {
  post: BlogPost;
}

const PostHoverCard: React.FC<PostHoverCardProps> = ({ post }) => {
  const formatDate = (dateString: string) => {
    const date = new Date(`${dateString}T12:00:00-08:00`);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className="absolute z-50 w-[32rem] p-6 bg-white rounded-lg shadow-xl border border-gray-200 -mt-2">
      <header className="mb-4">
        <h3 className="text-xl font-bold text-gray-900 mb-1">{post.title}</h3>
        <time className="text-sm text-gray-600">{formatDate(post.date)}</time>
      </header>
      <div className="prose prose-sm max-w-none text-gray-700">
        {post.content}
      </div>
    </div>
  );
};

interface BlogPostProps {
  post: BlogPost;
}

const BlogPost: React.FC<BlogPostProps> = ({ post }) => {
  const paragraphs = post.content.split("\n\n");
  const [hoveredPost, setHoveredPost] = useState<BlogPost | null>(null);

  const formatDate = (dateString: string) => {
    const date = new Date(`${dateString}T12:00:00-08:00`);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <article className="mb-12 bg-white rounded-lg shadow-sm overflow-hidden border border-gray-100">
      <div className="px-8 py-6">
        <header className="mb-6">
          <h2 className="text-2xl font-bold text-gray-900 mb-2">
            {post.title}
          </h2>
          <time className="text-sm text-gray-600" dateTime={post.date}>
            {formatDate(post.date)}
          </time>
        </header>

        <div className="prose max-w-none">
          {paragraphs.map((paragraph, index) => {
            const contentKey = `${post.id}-p-${index}-${paragraph.slice(0, 20)}`;

            // Handle lists
            if (paragraph.startsWith("- ")) {
              const items = paragraph.split("\n");
              return (
                <ul
                  key={contentKey}
                  className="list-disc pl-6 mb-6 text-gray-700"
                >
                  {items.map((item) => {
                    const cleanItem = item.replace("- ", "");
                    const itemKey = `${contentKey}-${cleanItem.slice(0, 20)}`;
                    return (
                      <li key={itemKey} className="mb-2">
                        {cleanItem}
                      </li>
                    );
                  })}
                </ul>
              );
            }

            // Handle links
            if (paragraph.includes("[") && paragraph.includes("]")) {
              const parts = paragraph.split(/(\[[^\]]*\]\([^\)]*\))/g);
              return (
                <p key={contentKey} className="mb-6 text-gray-700">
                  {parts.map((part, pIndex) => {
                    if (part.match(/\[[^\]]*\]\([^\)]*\)/)) {
                      const [, text, url] =
                        part.match(/\[([^\]]*)\]\(([^\)]*)\)/) || [];
                      const linkKey = `${contentKey}-link-${pIndex}-${url}`;

                      // Check if this is a blog post link
                      const { post: linkedPost } = findPostByPath(url);

                      if (linkedPost) {
                        return (
                          <span
                            key={linkKey}
                            className="relative"
                            onMouseEnter={() => setHoveredPost(linkedPost)}
                            onMouseLeave={() => setHoveredPost(null)}
                          >
                            <Link
                              to={url}
                              className="text-blue-600 hover:text-blue-800 hover:underline"
                            >
                              {text}
                            </Link>
                            {hoveredPost?.id === linkedPost.id && (
                              <PostHoverCard post={linkedPost} />
                            )}
                          </span>
                        );
                      }

                      return (
                        <Link
                          key={linkKey}
                          to={url}
                          className="text-blue-600 hover:text-blue-800 hover:underline"
                        >
                          {text}
                        </Link>
                      );
                    }
                    return part;
                  })}
                </p>
              );
            }

            // Regular paragraphs
            return (
              <p
                key={contentKey}
                className="mb-6 text-gray-700 whitespace-pre-line"
              >
                {paragraph}
              </p>
            );
          })}
        </div>
      </div>
    </article>
  );
};

const DevBlog: React.FC = () => {
  const [activeTab, setActiveTab] = useState("Updates");
  const roadmapRef = useRef<HTMLDivElement>(null);

  const scrollToRoadmap = useCallback(() => {
    roadmapRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (activeTab === "Roadmap") {
      scrollToRoadmap();
    }
  }, [activeTab, scrollToRoadmap]);

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4">
        <div className="mb-8">
          <div className="flex items-center text-sm text-gray-500 mb-4">
            <Link to="/" className="hover:text-blue-600">
              Home
            </Link>
            <ChevronRight size={16} className="mx-2" />
            <span>Developer Blog</span>
          </div>
          <h1 className="text-4xl font-bold text-gray-900 mb-2">
            Developer Updates
          </h1>
          <p className="text-lg text-gray-600">
            Stay up to date with the latest technical improvements and platform
            updates
          </p>
        </div>

        <div className="mb-8">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8">
              {Object.keys(blogPosts).map((tabName) => (
                <button
                  key={`tab-${tabName}`}
                  onClick={() => setActiveTab(tabName)}
                  className={`py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === tabName
                      ? "border-blue-500 text-blue-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  }`}
                  type="button"
                >
                  {tabName}
                </button>
              ))}
            </nav>
          </div>
        </div>

        {/* Updates section */}
        <div className={`space-y-8 ${activeTab === "Updates" ? "" : "hidden"}`}>
          {blogPosts.Updates.map((post) => (
            <BlogPost key={`post-${post.id}`} post={post} />
          ))}
        </div>

        {/* Roadmap section */}
        <div
          ref={roadmapRef}
          className={`space-y-8 ${activeTab === "Roadmap" ? "" : "hidden"}`}
        >
          {blogPosts.Roadmap.map((post) => (
            <BlogPost key={`post-${post.id}`} post={post} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DevBlog;
