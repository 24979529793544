import type React from "react";
import { useState } from "react";
import AsymptoteRenderer from "./AsymptoteRenderer";

const AsymptoteRendererDemo: React.FC = () => {
  const [codeInput, setCodeInput] = useState("draw((0,0)--(100,100));");
  const [codeToRender, setCodeToRender] = useState(codeInput);

  const handleRenderClick = () => {
    setCodeToRender(codeInput);
  };

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Asymptote Renderer Demo</h1>
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 pr-4">
          <textarea
            className="w-full h-64 p-2 border rounded"
            value={codeInput}
            onChange={(e) => setCodeInput(e.target.value)}
            placeholder="Enter Asymptote code here"
          />
          <button
            className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
            onClick={handleRenderClick}
            type="submit"
          >
            Render
          </button>
        </div>
        <div className="md:w-1/2">
          <AsymptoteRenderer code={codeToRender} />
        </div>
      </div>
    </div>
  );
};

export default AsymptoteRendererDemo;
